import React, { useState } from "react";
import styled from "styled-components"
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";



const Container = styled('div')`
display: flex;
padding: 28px 24px;
flex-direction: column;
align-items: flex-start;
gap: 14px;
align-self: stretch;
background: ${colors.white}
`

const Title = styled('div')`
display: flex;
padding: 0px 12px;
align-items: center;
gap: 10px;
align-self: stretch;
`

const Body = styled('div')`
display: flex;
padding: 16px 0px;
flex-direction: column;
align-items: flex-start;
gap: 14px;
align-self: stretch;
`

const QuestionContainer = styled('div')`
display: flex;
align-self: stretch;
flex-direction: column;
`

const Question = styled('div')`
display: flex;
padding: 0px 12px;
align-items: center;
gap: 10px;
align-self: stretch;
margin-bottom: 12px; /* Space between question and answer */
cursor: pointer; /* Change cursor to indicate clickable */
`

const Answer = styled('div')`
display: flex;
padding: 0px 12px;
align-items: center;
gap: 10px;
align-self: stretch;
`

const BodyText = styled(TypographyStyles.BodyMRegular)`
    margin-bottom: 16px; /* Adjust this value as needed */
`;


const Line = styled('div')`
width: 100%;
height: 0.5px;
background: ${colors.grey300};
`


const FAQ = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null); // Track which question is expanded
    
    // Define your questions and answers
    const faqItems = [
        {
            question: "For which trains does chuuchuu offer statistics?",
            answer: (
                <>
                    Currently, we offer delay, cancellation, and transfer statistics for the following trains:
                    <ul>
                        <li>🇩🇪 Germany: IC, EC, ICE, Railjet, TGV, FlixTrain, WestBahn; EuroNight, Nightjet and European Sleeper night trains</li>
                        <li>🇳🇱 Netherlands: IC Direct, IC, ICE, Eurostar, Sprinter, Stoptrein; Nightjet and European Sleeper night trains</li>
                        <li>🇧🇪 Belgium: IC, EC, L, Eurostar; Nightjet and European Sleeper night trains</li>
                        <li>🇫🇷 France: TGV inOui/Ouigo/Lyria, ICE, Eurostar, Frecciarossa, Intercités, Intercités de nuit night trains</li>
                        <li>🇨🇭 Switzerland: IC, EC, ICE, TGV Lyria, Railjet; EuroNight and Nightjet night trains</li>
                        <li>🇦🇹 Austria: IC, EC, ICE, Railjet, WestBahn; EuroNight and Nightjet night trains</li>
                        <li>🇬🇧 UK: Eurostar</li>
                        <li>Denmark, Poland, Czechia, Italy: select trains that originate from the countries above</li>
                    </ul>
                </>
            ),
        },
        {
            question: "Is chuuchuu free?",
            answer: "chuuchuu's journey planning and delay statistics are accessible for free. We may introduce paid features later on!",
        },
        {
            question: "Can I book a ticket on chuuchuu?",
            answer: "Not yet. We may add booking functionality later on. For now, chuuchuu is focused on letting you find the most reliable train journeys so you know which train to book.",
        },
        {
            question: "How can I get the data behind chuuchuu?",
            answer: "You can get access to all our delay, cancellation and transfer statistics through our chuuchuu API. Contact us with your use case and we will get in touch with our best offer!",
        },
        {
            question: "Is chuuchuu a finished product?",
            answer: "No - at this moment chuuchuu is still very much in beta. You are welcome to try out all of our functionalities. If you find any bugs or have ideas on how chuuchuu can be further improved, then please reach out to us!",
        },
        // Add more items as needed
    ];

    const toggleAnswer = (index: number) => {
        // Toggle the answer by setting the expanded index
        setExpandedIndex(prevIndex => (prevIndex === index ? null : index));
    };

    return (
      <Container>
        <Title>
            <TypographyStyles.BodyXLSemibold color={colors.black}>Frequently Asked Questions</TypographyStyles.BodyXLSemibold>
        </Title>
        <Body>
            {faqItems.map((item, index) => (
                <QuestionContainer key={index}>
                    <Question onClick={() => toggleAnswer(index)}>
                        <TypographyStyles.BodyLSemibold color={colors.grey800}>
                            {item.question}
                        </TypographyStyles.BodyLSemibold>
                    </Question>
                    {expandedIndex === index && ( // Show answer only if the index matches
                        <Answer>
                            <BodyText color={colors.grey600}>
                                {item.answer}
                            </BodyText>
                        </Answer>
                    )}
                    {/* Add a line separator between items, but not after the last one */}
                    {index < faqItems.length - 1 && <Line />}
                </QuestionContainer>
            ))}
        </Body>
      </Container>
    );
  };
  
  export default FAQ;