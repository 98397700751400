// src/utils/fetchJourneys.ts

import { MappedJourney, mapJourneysData, Journey } from './mapJourneysData';

type FetchJourneysParams = {
    type?: 'earlier' | 'later' | 'initial';
    originId: string;
    destinationId: string;
    date: string;
    tripType: 'departure' | 'arrival';
    options: Record<string, string>;
    earlierThan: string | null;
    laterThan: string | null;
    existingJourneys?: Journey[];
    onJourneysFetched: (journeys: MappedJourney[], newEarlierThan: string | null, newLaterThan: string | null) => void;
    onError: (message: string) => void;
    onLoadingChange: (isLoading: boolean) => void;
};

export const fetchJourneys = ({
    type = 'initial',
    originId,
    destinationId,
    date,
    tripType,
    options,
    earlierThan,
    laterThan,
    existingJourneys = [],
    onJourneysFetched,
    onError,
    onLoadingChange,
  }: FetchJourneysParams) => {
    
  // Start loading
  onLoadingChange(true);

  // Make the request to the Netlify function
  fetch('/.netlify/functions/getJourneys', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type,
      originId,
      destinationId,
      date,
      tripType,
      options,
      earlierThan,
      laterThan,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      const { earlierThan: newEarlierThan, laterThan: newLaterThan, journeys: newJourneys } = data;

      // Map journeys using the existing journeys passed as parameter
      const mappedJourneys = mapJourneysData(newJourneys as Journey[], type === 'initial' ? [] : existingJourneys);

      // Notify the parent component of the fetched data
      onJourneysFetched(mappedJourneys, newEarlierThan, newLaterThan);
    })
  .catch((error) => {
      onError(error.message || `We're not sure what just happened there...`);
    })
    .finally(() => {
      onLoadingChange(false);
  });
}
