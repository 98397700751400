import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import { MappedJourney } from '../utils/mapJourneysData';
import { EarlierButton, LaterButton } from './Controls/EarlierLaterButtons';
import JourneyCard from './JourneyCard';
import FeedbackCard from './FeedbackCard';


// Styled component for the journey list container
const JourneyListContainer = styled.div`
  margin-bottom: 24px;
  padding: 0 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the buttons */
  align-items: center;
  width: auto;
  margin: 24px;
`;

// Styled component for the journey cards container
const JourneyCardsContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack journey cards vertically */
  gap: 32px; /* Space between individual journey cards */
`;

export const JourneyList: React.FC<{
  journeys: MappedJourney[];
  earlierThan: string | null;
  laterThan: string | null;
  loading: boolean; // Add loading prop to the component
  fetchJourneys: (type: 'earlier' | 'later') => void;
  originName: string;
  destinationName: string;
  date: string;
  tripType: string;
}> = ({ journeys, earlierThan, laterThan, loading, fetchJourneys, originName, destinationName, date, tripType }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleJourneyClick = (journeyId: string) => {
    navigate(`/journey/${journeyId}`, { state: { from: location.pathname } });
  };

  return (
    <JourneyListContainer> {/* Wrap the content with the new styled container */}
      {earlierThan && !loading && (
          <ButtonContainer>
          <EarlierButton onClick={() => fetchJourneys('earlier')} />
        </ButtonContainer>
      )}
      <JourneyCardsContainer>
        {journeys.length > 0 &&
          journeys.map((journey) => (
            <JourneyCard
              onClick={() => handleJourneyClick(journey.id)}
              key={journey.id}
              journey={journey}
            />
          ))
        }
      </JourneyCardsContainer>
      {laterThan && !loading && (
        <ButtonContainer>
          <LaterButton onClick={() => fetchJourneys('later')} />
        </ButtonContainer>
      )}    
      <FeedbackCard source={`Journey options from ${originName} to ${destinationName}, ${tripType} on ${date}`}/>
    </JourneyListContainer>
  );
};