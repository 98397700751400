import styled from "styled-components"
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import Carousel from "./Carousel";


const Container = styled('div')`
display: flex;
padding: 40px 0px 24px 0px;
flex-direction: column;
align-items: center;
gap: 32px;
background: ${colors.keyBackground};
align-self: stretch;
`

const Caption = styled('div')`
display: flex;
padding: 0px 32px;
flex-direction: column;
align-items: center;
gap: 12px;
align-self: stretch;
`

const MainCaption = styled(TypographyStyles.BodyXLSemibold)`
color: ${colors.keyDarkest};
leading-trim: both;
text-edge: cap;
font-size: 36px;
line-height: 40px; /* 111.111% */
text-align: center;
`

const PageSubtitle = styled(TypographyStyles.PageSubtitle)`
  color: ${colors.keyDarker};
  text-align: center; /* Center-align text */
`;



const Hero = () => {
    return (
      <Container>
        <Caption>
            <MainCaption>Train travel,<br /> 
            made smart</MainCaption>
            <PageSubtitle>Plan the journey that gives you peace of mind.</PageSubtitle>
        </Caption>
        <Carousel></Carousel>
      </Container>
    );
  };
  
  export default Hero;