import styled from "styled-components"
import { colors } from "../../utils/colors";
import { useNavigate } from 'react-router-dom';

import PromoCard from "./PromoCard";


const Container = styled('div')`
display: flex;
padding: 28px 24px;
flex-direction: column;
align-items: center;
gap: 10px;
align-self: stretch;
background: ${colors.keyHover};
`

const ClickableCard = styled('div')`
    cursor: pointer; /* Change cursor to pointer for better UX */
`;

const API = () => {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Function to handle PromoCard click
    const handleCardClick = () => {
        navigate('/api'); // Navigate to the /api page
    };

    return (
        <Container>
            <ClickableCard onClick={handleCardClick}>
                <PromoCard
                    imageUrl="/images/ScreensRain.png"
                    title="Use our data"
                    body="Connect to the chuuchuu API to access historical delay data and statistics across Europe’s rail network."
                    showLearnMore={true}
                />
            </ClickableCard>
        </Container>
    );
  };
  
  export default API;