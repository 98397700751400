import styled from "styled-components"
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import logo from '../../icons/chuuchuu-beta-logo.svg';
import menu from '../../icons/hamburgericon.svg';
import cross from '../../icons/cross.svg';


const Container = styled('div')<{ menuOpen: boolean }>`
display: flex;
padding: 12px 16px;
justify-content: space-between;
align-items: center;
background: ${colors.white};
`

const LogoWrapper = styled("div")<{ menuOpen: boolean }>`
cursor: pointer;  
& svg {
    fill: ${colors.keyDarkest};
    height: 28px; /* Set height to 14px */
    width: auto;  /* Maintain aspect ratio */
`;

const MenuButton = styled("div")<{ menuOpen: boolean }>`
cursor: pointer;  
& svg {
    stroke: ${colors.keyDarker};
    fill: ${colors.keyDarker};
    height: 24px; 
    width: auto;  /* Maintain aspect ratio */
`;

const MenuOverlay = styled("div")`
  position: absolute;
  top: 54px; /* Position it right below the header */
  left: 0; /* Align the menu to the left side */
  right: 0; /* Align the menu to the left side */
  display: flex;
  padding: 32px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background-color: ${colors.white}; /* Change to your preferred color */
  z-index: 100; /* Ensure it's on top of other elements */
`;

const MenuItem = styled(TypographyStyles.MenuItem)`
cursor: pointer;

&:hover {
  color: ${colors.keyDarkest}; /* Change this to the color you want on hover */
}
`

const Header = () => {
  const navigate = useNavigate();  // Use the useNavigate hook
  const [menuOpen, setMenuOpen] = useState(false); // State for menu visibility

  // Define the function to handle logo click
  const handleLogoClick = () => {
    navigate('/');  // Navigate to the homepage ("/")
  };

  const toggleMenu = () => {
    setMenuOpen(prev => !prev); // Toggle the menu visibility
  };

  // Function to handle navigation to the API page
  const handleApiClick = () => {
    navigate('/api'); // Navigate to the API page
    setMenuOpen(false); // Close the menu after navigation
  };

  const handleContactClick = () => {
    navigate('/contact'); // Navigate to the /api page
    setMenuOpen(false); // Close the menu after navigation
  };

  return (
    <Container menuOpen={menuOpen}>
      <LogoWrapper menuOpen={menuOpen} onClick={handleLogoClick}> 
        <ReactSVG src={logo} /> 
      </LogoWrapper>
      <MenuButton menuOpen={menuOpen} onClick={toggleMenu}>
        <ReactSVG src={menuOpen ? cross : menu} /> {/* Swap between menu and cross */}
      </MenuButton>
            
      {menuOpen && (
        <MenuOverlay>
          <MenuItem color={colors.keyDarker} onClick={handleApiClick}>API</MenuItem>
          <MenuItem color={colors.keyDarker} onClick={handleContactClick}>Contact</MenuItem>
        </MenuOverlay>
      )}
    </Container>
  );
};
  
export default Header;