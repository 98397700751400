import styled from 'styled-components';
import * as TypographyStyles from './TypographyStyles';
import { colors } from "../utils/colors";
import BackButton from "./Controls/BackButton";
import { TextButtonM } from "./Controls/Buttons"


const Header = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    background-color: ${colors.white};
`

const NavBar = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 10px;
    align-self: stretch;
    flex-wrap: wrap;
`

const TitleContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
`

type PageHeaderProps = {
    title: string;
    subtitle?: string;
    backButtonTitle?: string;
    textButtonTitle?: string; // New prop for TextButtonM text
    textButtonOnClick?: () => void; // New prop for TextButtonM click handler
    showTextButton?: boolean; // New prop to conditionally show the button
    showTitleContainer?: boolean; // New prop to conditionally hide the title container
};

const PageHeader = ({
    title,
    subtitle,
    backButtonTitle = "Back",
    textButtonTitle, // Destructure new prop
    textButtonOnClick, // Destructure new prop
    showTextButton = false, // Default to false
    showTitleContainer = true, // Default to true
}: PageHeaderProps) => {

    return (
        <Header>
            <NavBar>
                <BackButton title={backButtonTitle} />
                {showTextButton && (
                    <TextButtonM onClick={textButtonOnClick}>
                        {textButtonTitle}
                    </TextButtonM>
                )}
            </NavBar>
            {showTitleContainer && ( // Conditionally render TitleContainer
                <TitleContainer>
                    <TypographyStyles.PageTitle color={colors.black}>{title}</TypographyStyles.PageTitle>
                    {subtitle && <TypographyStyles.PageSubtitle color={colors.grey600}>{subtitle}</TypographyStyles.PageSubtitle>}
                </TitleContainer>
            )}
        </Header>
    );
};

export default PageHeader;