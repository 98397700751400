import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { DelayHistory } from './Types/delayHistory';
import DelayIndication from '../DelayIndication';
import { NextButton, PrevButton } from '../Controls/PrevNextButtons';


const ContainerCard = styled('div')`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const DelayHistoryTable = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
`

const DataRow = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`

const RowContent = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 12px;
`

const DateContainer = styled('div')`
    display: flex;
    min-width: 62px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`

const Divider = styled.div`
  height: 0.5px;
  background-color: ${colors.grey300}; // Default color if none is provided
  width: 100%;
  margin: 0;
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
`;

const PaginatorContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`;



interface DelayHistoryCardProps {
    delayHistoryData: DelayHistory[];
    selectedOption: 'origin' | 'destination';
}

const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
  
    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();
  
    if (isToday) {
      return 'Today';
    } else if (isYesterday) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString('nl-BE', { month: '2-digit', day: '2-digit' });
    }
};

const getDisplayInfo = (
    delay: number | null | undefined,
    plannedTime: string | null | undefined,
    cancelled: boolean
) => {
    if (cancelled) {
        return { displayText: 'Cancelled', displayColor: colors.red };
    }
    if (!plannedTime) {
        return { displayText: 'not served', displayColor: colors.grey600 };
    }
    if (delay === null || delay === undefined) {
        return { displayText: 'no data', displayColor: colors.grey600 };
    }
    if (delay <= 30) {
        return { displayText: 'On time', displayColor: colors.green };
    }
    return { displayText: null, displayColor: null };
};

const PAGE_SIZE = 10;

const DelayHistoryCard: React.FC<DelayHistoryCardProps> = ({ delayHistoryData, selectedOption }) => {
    const [currentPage, setCurrentPage] = useState(1);
    
    // Determine which date to use based on selectedOption
    const getDate = (entry: DelayHistory) => selectedOption === 'origin' ? entry.plannedDeparture : entry.plannedArrival;
    const getDelay = (entry: DelayHistory) => selectedOption === 'origin' ? entry.departureDelay : entry.arrivalDelay;
    const isCancelled = (entry: DelayHistory): boolean => {
        return !!(entry.cancelled || (selectedOption === 'origin' ? entry.departureCancelled : entry.arrivalCancelled));
    }

    // Sort the delay history data in reverse chronological order
    const sortedData = [...delayHistoryData].sort((a, b) => {
        const dateA = getDate(a) ? new Date(getDate(a)!) : new Date(0);
        const dateB = getDate(b) ? new Date(getDate(b)!) : new Date(0);
        return dateB.getTime() - dateA.getTime(); // Most recent dates first
    });

    // Pagination logic
    const totalPages = Math.ceil(sortedData.length / PAGE_SIZE);
    const paginatedData = sortedData.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    return (
        <ContainerCard>
            <CardHeader>
                <TypographyStyles.BodyLSemibold color={colors.black}>Delay history</TypographyStyles.BodyLSemibold>
                <TypographyStyles.BodyLRegular color={colors.grey600}>
                    (last 30 days)
                </TypographyStyles.BodyLRegular>
            </CardHeader>
            <DelayHistoryTable>
                {paginatedData.map((entry, index) => {
                        const delay = getDelay(entry);
                        const plannedTime = getDate(entry);
                        const cancelled = isCancelled(entry);
                        
                        const { displayText, displayColor } = getDisplayInfo(delay, plannedTime, cancelled);

                        return (
                    <React.Fragment key={index}>
                        <DataRow>
                            <RowContent>
                                <DateContainer>
                                    <TypographyStyles.BodyMRegular color={colors.grey800}>
                                        {formatDate(getDate(entry))}
                                    </TypographyStyles.BodyMRegular>
                                </DateContainer>
                                    {displayText ? (
                                            <TypographyStyles.BodyMRegular color={displayColor}>
                                                {displayText}
                                            </TypographyStyles.BodyMRegular>
                                        ) : (
                                            <DelayIndication delay={delay!} timeUnit="seconds" size={14} />
                                        )}
                            </RowContent>
                        </DataRow>
                        {index < paginatedData.length - 1 && <Divider />}
                    </React.Fragment>
                    );
                })}
            </DelayHistoryTable>
            <CardFooter>
                <TypographyStyles.BodyMRegular color={colors.grey600}>Page {currentPage} of {totalPages}</TypographyStyles.BodyMRegular>
            <PaginatorContainer>
                <PrevButton onClick={handlePrevPage} disabled={currentPage === 1} />
                <NextButton onClick={handleNextPage} disabled={currentPage === totalPages} />
                </PaginatorContainer>
            </CardFooter>
        </ContainerCard>
    );
};


export default DelayHistoryCard;