import styled from "styled-components"
import { colors } from "../utils/colors";
import * as TypographyStyles from './TypographyStyles';



const Container = styled('div')`
display: flex;
padding: 28px 24px;
flex-direction: column;
align-items: flex-start;
gap: 18px;
align-self: stretch;
background: ${colors.white};
`

const Title = styled('div')`
display: flex;
padding: 0px 12px;
align-items: center;
gap: 10px;
align-self: stretch;
`

const List = styled('div')`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 14px;
align-self: stretch;
`

const ListItem = styled('div')`
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
align-self: stretch;
padding: 12px 12px;
gap: 4px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid ${colors.keyDarker};
cursor: pointer;
`

interface RecentSearch {
  originId: string;
  destinationId: string;
  originName: string;
  destinationName: string;
  date: string;
  tripType: string;
  options: Record<string, string>;
  timestamp: string;
}

interface RecentsProps {
  recentSearches: RecentSearch[];
}

const formatDate = (dateString: string) => {
  const searchDate = new Date(dateString);
  const now = new Date();

  const isToday =
  searchDate.getDate() === now.getDate() &&
  searchDate.getMonth() === now.getMonth() &&
  searchDate.getFullYear() === now.getFullYear();
  
  const isTomorrow =
    searchDate.getDate() === now.getDate() + 1 &&
    searchDate.getMonth() === now.getMonth() &&
    searchDate.getFullYear() === now.getFullYear();

    if (isToday) {
      return `today at ${searchDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else if (isTomorrow) {
      return `tomorrow at ${searchDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      const datePart = searchDate.toLocaleDateString([], {
        day: 'numeric',
        month: 'numeric'
      });
      const timePart = searchDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${datePart} at ${timePart}`;
  }
};


const Recents: React.FC<RecentsProps & { 
  onSearch: (search: RecentSearch) => void 
}> = ({ recentSearches, onSearch }) => {
  
  const handleListItemClick = (search: RecentSearch) => {
    console.log(search)
    onSearch(search);  // Trigger search with the clicked recent search's data
  };

  return (
    <Container>
      <Title>
        <TypographyStyles.BodyXLSemibold color={colors.black}>Search again</TypographyStyles.BodyXLSemibold>
      </Title>
      <List>
      {recentSearches.slice().reverse().map((search, index) => (
        <ListItem key={index} onClick={() => handleListItemClick(search)}>
          <TypographyStyles.BodyLSemibold color={colors.keyDarker}>{search.originName} ➜ {search.destinationName}</TypographyStyles.BodyLSemibold>
          <TypographyStyles.BodyMRegular color={colors.grey600}>
              {search.tripType === 'departure' ? 'departing' : 'arriving'} {formatDate(search.date)}
          </TypographyStyles.BodyMRegular>        
        </ListItem>
      ))}
      </List>
    </Container>
    );
  };
  
  export default Recents;