import styled from 'styled-components';
import { colors } from '../utils/colors';
import { Tooltip } from 'react-tooltip';

// Define a styled tooltip with custom styles
export const StyledTooltip = styled(Tooltip)`
  &.tooltip-styled {  // Add a class name
    max-width: 80%;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    background-color: ${colors.grey800};
    color: ${colors.white};
    padding: 12px;
    border-radius: 8px;
    opacity: 1; // Ensure the tooltip is fully opaque
    font-size: 12px;
  }
`;