import styled from 'styled-components';
import { colors } from '../../utils/colors'


// Define prop types for the Switch component
interface SwitchProps {
    id: string;
    toggled: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 52px;
  height: 32px;
  background: ${colors.grey300};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  -webkit-tap-highlight-color: transparent;

    /* Change background color when the input is checked */
  ${SwitchInput}:checked + & {
    background: ${colors.key};
  }
`;

const SwitchButton = styled.span`
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 45px;
  transition: 0.2s;
  background: ${colors.white};  
  -webkit-tap-highlight-color: transparent;
  
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 32px;
  }
`;

const Switch: React.FC<SwitchProps> = ({ id, toggled, onChange }) => {
    return (
      <>
        <SwitchInput
          id={id}
          type="checkbox"
          checked={toggled}
          onChange={onChange}
        />
        <SwitchLabel htmlFor={id}>
          <SwitchButton />
        </SwitchLabel>
      </>
    );
  };
  
export default Switch;
