import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import styled from "styled-components";
import { getJourneyDurationInMinutes, formatDuration } from "../../utils/durationUtils";
import DelayIndication from "../DelayIndication";

type TimeIndicationProps = {
  legs: any;
}

const TimeInfo = styled('div')`
  display: flex;
  justify-content: space-between;
`
const TimeInfoTimes = styled('div')`
  display: flex;
  grid-column-gap: 8px;
  align-items: center;
`

type TimestampProps = {
  timestamp: string;
  delayInMinutes?: number;
}

// Utility function to extract time from ISO string without timezone conversion
const formatTimeWithoutTimezoneConversion = (time: string): string => {
  const isoTime = time.split('T')[1]; // Extract time part (HH:mm:ss...)
  const [hours, minutes] = isoTime.split(':'); // Get hours and minutes

  // Return the time in HH:mm format
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

const Timestamp = ({ timestamp, delayInMinutes }: TimestampProps) => {
  const isOnTime = delayInMinutes === 0;
  return <div style={{ display: 'flex', gridColumnGap: '4px' }}>
    <TypographyStyles.BodyLBold color={isOnTime ? colors.green : colors.black}>{timestamp}</TypographyStyles.BodyLBold>
    {delayInMinutes ? <>{' '}<DelayIndication weight={700} delay={delayInMinutes} /></> : null}
    </div>
}

const TimeIndication = ({ legs }: TimeIndicationProps) => {
  const originLeg = legs[0];
  const destinationLeg = legs[legs.length-1];
  
  const startTime = formatTimeWithoutTimezoneConversion(originLeg.plannedDeparture);
  const arrivalTime = formatTimeWithoutTimezoneConversion(destinationLeg.plannedArrival);
  const durationInMinutes = getJourneyDurationInMinutes(legs)
  const formattedDuration = formatDuration(durationInMinutes);

  return (
    <TimeInfo>
    <TimeInfoTimes>
      <Timestamp timestamp={startTime} delayInMinutes={originLeg.departureDelay} />
      <TypographyStyles.BodyMRegular color={colors.black}>➜</TypographyStyles.BodyMRegular>
      <Timestamp timestamp={arrivalTime} delayInMinutes={destinationLeg.arrivalDelay} />
    </TimeInfoTimes>
    <TypographyStyles.BodyLRegular color={colors.grey600}>{formattedDuration}</TypographyStyles.BodyLRegular>
  </TimeInfo>

  )
}

export default TimeIndication;