import styled from "styled-components"
import * as TypographyStyles from '../TypographyStyles';
import { colors } from "../../utils/colors";
import { useNavigate } from 'react-router-dom';


const Container = styled('div')`
display: flex;
padding: 40px;
justify-content: space-between; /* Space between the columns */
align-items: flex-start;
background: ${colors.grey200};
width: 100%;
box-sizing: border-box; /* Include padding in the element's total width */
`

const Column = styled('div')`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 32px;
flex: 1; /* Allow each column to grow equally */
`

const Links = styled('div')`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 14px;
`

const Socials = styled('div')`
display: flex;
align-items: flex-start;
gap: 10px;
`

const Link = styled(TypographyStyles.BodyMRegular)`
color: ${colors.grey800};
cursor: pointer;
`


const Sitemap = () => {
    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Function to handle API link click
    const handleAPIClick = () => {
        navigate('/api'); // Navigate to the /api page
    };

    const handleContactClick = () => {
        navigate('/contact'); // Navigate to the /api page
    };

    const handleTermsClick = () => {
        navigate('/terms'); // Navigate to the /terms page
    };

    const handlePrivacyClick = () => {
        navigate('/privacy'); // Navigate to the /privacy page
    };

    return (
      <Container>
        <Column>
            <Links>
                <Link onClick={handleAPIClick}>chuuchuu API</Link>
                <Link onClick={handleContactClick}>Contact</Link>
            </Links>
            <Socials>

            </Socials>
        </Column>
        <Column>
            <Links>
                <Link onClick={handleTermsClick}>Terms and Conditions</Link>
                <Link onClick={handlePrivacyClick}>Privacy</Link>
            </Links>
        </Column>
      </Container>
    );
  };
  
  export default Sitemap;