import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sendToMixpanel from './utils/sendToMixpanel';

// Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';

// Initialize Mixpanel with your project token
mixpanel.init('9301ea9f0799438a00c9ee3ead0c9947', {
  debug: true, // Enables debug mode
  track_pageview: true, // Automatically tracks page views
  persistence: 'localStorage', // Stores data in localStorage
});

// Track the initial page load
mixpanel.track("Page Loaded");


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToMixpanel);
