import React from 'react';
import styled from 'styled-components';
import { colors } from '../../utils/colors'
import * as TypographyStyles from '../TypographyStyles';


const TextFieldContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
  align-self: stretch;
`

const Instruction = styled(TypographyStyles.BodySRegular)<{ isBackgroundDark?: boolean }>`
  color: ${({ isBackgroundDark }) => (isBackgroundDark ? colors.white : colors.keyDarkest)};
`;

const ErrorMessage = styled(TypographyStyles.BodySSemibold)`
color: ${colors.red300};
`

export const Input = styled.input<{ hasError?: boolean }>`
  font-size: 20px;
  font-weight: 400;
  font-family: 'DRPublikSemibold';
  padding: 9px 14px 9px 14px;
  border-radius: 8px;
  border: 2px solid ${colors.key};
  background: ${colors.white};
  flex: 1 1 auto; /* Allows the input to grow and shrink */
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    border: 2px solid ${colors.keyDarkest}; /* Border color when active */
    outline: none; /* Removes default focus outline */
  }

  &:active {
    border: 2px solid ${colors.keyDarkest};
  }
  
  &:disabled {
    border: 2px solid ${colors.grey400};   
    background: ${colors.grey200}; 
    cursor: not-allowed; /* Change cursor style when disabled */
  }

  ${({ hasError }) =>
    hasError &&
    `
      border: 2px solid ${colors.red}; /* Border color when there is an error */
    `}
`;

interface TextFieldProps {
    instruction: string;
    dynamicPlaceholder: string;
    isDisabled: boolean;
    isError: boolean;
    errorMessage?: string;
    value: string;  
    isBackgroundDark?: boolean;  
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextField: React.FC<TextFieldProps> = ({
  instruction,
  dynamicPlaceholder,
  isDisabled,
  isError,
  errorMessage,
  value,  // Receive value from props
  isBackgroundDark = true,
  onChange,  // Receive onChange handler from props
}) => {
    return (
      <>
        <TextFieldContainer>
            {isError ? (
              <ErrorMessage>
                {errorMessage} {/* Show error message if isError is true */}
              </ErrorMessage>
            ) : (
              <Instruction isBackgroundDark={isBackgroundDark}>{instruction}</Instruction>
            )}
            <Input
              type="text"
              value={value}
              placeholder={!value ? dynamicPlaceholder : ''}
              disabled={isDisabled}
              hasError={isError}
              onChange={onChange}
            />
        </TextFieldContainer>
      </>
    );
};
  

const TextArea = styled.textarea<{ hasError?: boolean }>`
    font-size: 20px;
    font-weight: 400;
    font-family: 'DRPublikSemibold';
    padding: 9px 18px;
    border-radius: 8px;
    border: 2px solid ${colors.key};
    background: ${colors.white};
    flex: 1 1 auto; /* Allows the textarea to grow and shrink */
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    resize: vertical; /* Allow vertical resizing only */
    min-height: 100px; /* Default height */

  &:focus {
    border: 2px solid ${colors.keyDarkest}; /* Border color when active */
    outline: none; /* Removes default focus outline */
  }

  &:active {
    border: 2px solid ${colors.keyDarkest}; /* You can also apply this for active state if desired */
  }  
  
  &:disabled {
    border: 2px solid ${colors.grey400};   
    background: ${colors.grey200}; 
    cursor: not-allowed; /* Change cursor style when disabled */
  }

  ${({ hasError }) =>
    hasError &&
    `
      border: 2px solid ${colors.red}; /* Border color when there is an error */
    `}
`;

interface MultiLineTextFieldProps {
  instruction: string;
  dynamicPlaceholder: string;
  isDisabled: boolean;
  isError: boolean;
  errorMessage?: string;
  value: string;
  isBackgroundDark?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;  // Update this to handle textarea events
}

export const MultiLineTextField: React.FC<MultiLineTextFieldProps> = ({    instruction,
    dynamicPlaceholder,
    isDisabled,
    isError,
    errorMessage,
    value,
    isBackgroundDark = true,
    onChange,
  }) => {  
    return (
      <TextFieldContainer>
        {isError ? (
          <ErrorMessage>
            {errorMessage} {/* Show error message if isError is true */}
          </ErrorMessage>
        ) : (
          <Instruction isBackgroundDark={isBackgroundDark}>{instruction}</Instruction>
        )}
        <TextArea
          value={value}
          placeholder={!value ? dynamicPlaceholder : ''}
          disabled={isDisabled}
          hasError={isError}
          onChange={onChange}
        />
      </TextFieldContainer>
    );
};