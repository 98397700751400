import styled from 'styled-components';
import { ReactComponent as DropdownArrows } from '../../icons/dropdownarrows.svg'; // Import as a URL
import { colors } from '../../utils/colors'


interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    children: React.ReactNode;
  }

// Base Button with common styles
const BaseDropdown = styled.div`
  position: relative; /* Positioning context for the arrow */
  display: inline-block;

  select {
    font-family: 'DrPublikSemibold';
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: 2px solid ${colors.key};
    background: ${colors.white};
    color: var(--Key, ${colors.key});
    position: relative;
    max-width: 100%; /* Ensure the dropdown does not exceed its container's width */
    width: 100%; /* Take full width of the parent container */

    -webkit-appearance: none; /* Remove default arrow on Safari and Chrome */
    appearance: none; /* Remove default arrow on other browsers */
    -webkit-tap-highlight-color: transparent;

    /* Ellipsis text overflow handling */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */

    &:hover {
      background: ${colors.keyBackground};
    }

    &:active {
      background: ${colors.keyHover};
    }

    &:disabled {
      background: none;
      border: 2px solid ${colors.grey400};
      cursor: not-allowed;
    }

    /* Adjust padding to make room for the custom arrow */
    padding-right: 30px;
  }

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  svg path {
    fill: var(--Key, ${colors.key});
  }
`;

// Small Dropdown with specific font size
const BaseDropdownS = styled(BaseDropdown)`
  select {
    font-size: 16px;
  }
`;

// Medium Dropdown with specific font size
const BaseDropdownM = styled(BaseDropdown)`
  select {
    font-size: 24px;
  }
`;



export const DropdownS: React.FC<DropdownProps> = ({ children, ...props }) => (
    <BaseDropdownS>
      <select {...props}>
        {children}
      </select>
      <DropdownArrows width={8} height={12} />
    </BaseDropdownS>
  );
  
  export const DropdownM: React.FC<DropdownProps> = ({ children, ...props }) => (
    <BaseDropdownM>
      <select {...props}>
        {children}
      </select>
      <DropdownArrows width={12} height={16} />
    </BaseDropdownM>
  );