import styled from 'styled-components';
import { ReactComponent as EarlierIcon } from '../../icons/earlier.svg';
import { ReactComponent as LaterIcon } from '../../icons/later.svg';
import { BodyLSemibold } from '../TypographyStyles';
import { colors } from '../../utils/colors'

const BaseButton = styled.button`
  display: inline-flex;
  width: auto; /* Ensure the button width adapts to its content */
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid ${colors.grey600};
  background: ${colors.white};
  cursor: pointer;
  color: ${colors.grey600};
  -webkit-tap-highlight-color: transparent;
  
  & > svg {
    width: 16px;
    height: 16px;
    fill: ${colors.grey600};  // Ensure the SVG uses this color
  }
`;

type Props = {
    onClick: () => void;
} 

export const EarlierButton: React.FC<Props> = ({ onClick }) => {
    return (
        <BaseButton onClick={onClick}>
            <EarlierIcon />
            <BodyLSemibold>Earlier</BodyLSemibold>
        </BaseButton>
    );
    }

export const LaterButton: React.FC<Props> = ({ onClick }) => {
    return (
        <BaseButton onClick={onClick}>
            <LaterIcon />
            <BodyLSemibold>Later</BodyLSemibold>
        </BaseButton>
    );
    }