import { ReportHandler } from 'web-vitals';
import mixpanel from 'mixpanel-browser';

const sendToMixpanel: ReportHandler = (metric) => {
  // Ensure values are reasonable for analytics
  const value = metric.name === 'CLS' ? metric.value * 1000 : Math.round(metric.value);
  
  // Track each web vital as a separate event
  mixpanel.track('Web Vital Metric', {
    metricId: metric.id,
    metricName: metric.name,
    metricValue: value,
    metricDelta: metric.delta,
    metricRating: getRating(metric.name, metric.value),
    page: window.location.pathname,
  });
};

// Helper function to determine rating based on Core Web Vitals thresholds
const getRating = (name: string, value: number): 'good' | 'needs-improvement' | 'poor' => {
  switch (name) {
    case 'CLS':
      return value <= 0.1 ? 'good' : value <= 0.25 ? 'needs-improvement' : 'poor';
    case 'FID':
      return value <= 100 ? 'good' : value <= 300 ? 'needs-improvement' : 'poor';
    case 'LCP':
      return value <= 2500 ? 'good' : value <= 4000 ? 'needs-improvement' : 'poor';
    case 'FCP':
      return value <= 1800 ? 'good' : value <= 3000 ? 'needs-improvement' : 'poor';
    case 'TTFB':
      return value <= 800 ? 'good' : value <= 1800 ? 'needs-improvement' : 'poor';
    default:
      return 'good';
  }
};

export default sendToMixpanel;