import bike from '../../icons/bike.svg'
import bikereservation from '../../icons/bikereservation.svg'
import plug from '../../icons/plug.svg'
import aircon from '../../icons/aircon.svg'
import wifi from '../../icons/wifi.svg'
import seatreservation from '../../icons/seatreservation.svg'
import comfortcheckin from '../../icons/comfortcheckin.svg'
import restaurant from '../../icons/restaurant.svg'
import ramp from '../../icons/ramp.svg'
import wheelchair from '../../icons/wheelchair.svg'
import ticketmachine from '../../icons/ticketmachine.svg'
import bar from '../../icons/bar.svg'
import nursing from '../../icons/nursing.svg'

import { StyledTooltip } from '../TooltipStyles';

type Props = {
  code?: string
}

const icons: { [x: string]: string } = {
  'bicycle-conveyance': bike,
  'bicycle-conveyance-reservation': bikereservation,
  'power-sockets': plug,
  'air-conditioned': aircon,
  'wifi': wifi,
  'compulsory-reservation': seatreservation,
  'komfort-checkin': comfortcheckin,
  'on-board-restaurant': restaurant,
  'boarding-ramp': ramp,
  'ER': ramp,
  'EA': wheelchair,
  'FM': ticketmachine,
  'OR': seatreservation,
	'BW': bar,
  'SA': nursing,
  'FG': seatreservation,
  '97': wheelchair
}

const tooltipTexts: { [x: string]: string } = {
  'bicycle-conveyance': 'Bike space',
  'bicycle-conveyance-reservation': 'Bikes require reservation',
  'power-sockets': 'Power socket',
  'air-conditioned': 'Air conditioned',
  'wifi': 'Wi-Fi',
  'compulsory-reservation': 'Seat reservation required',
  'komfort-checkin': 'Comfort check-in available',
  'on-board-restaurant': 'On-board restaurant',
  'boarding-ramp': 'Boarding ramp',
  'ER': 'Boarding ramp',
  'EA': 'Wheelchair accessible',
  'FM': 'Ticket machine inside',
  'OR': 'Seat reservation required',
  'BW': 'Bar',
  'SA': 'Nursing room',
  'FG': 'Seat reservation required',
  '97': 'Wheelchair accessible'
};

const AmenityIcon = ({ code }: Props) => {
  if (!code) return null;
  const icon = icons[code];

  if (!icon) return null;

  const tooltipText = tooltipTexts[code] || 'Unknown Amenity'; // Default text for missing tooltips

  return (
    <>
      <img
        alt={code}
        style={{ height: '14px' }}
        src={icon}
        data-tooltip-id={`tooltip-${code}`}
        data-tooltip-content={tooltipText}
      />
      <StyledTooltip id={`tooltip-${code}`} place="top" className="tooltip-styled" />
    </>
  );
};

export default AmenityIcon;