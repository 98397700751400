import chevron from '../../icons/Chevron.svg'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';


const BackContainer = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  `

const ArrowButton = styled('div')`
  background-color: ${colors.key};
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  box-sizing: border-box;
  & > img {
    width: 16px;
    height: 16px;
  }
`

type Props = {
  title: string;
  to?: string; // Optional prop to specify exact back navigation
}

const BackButton = ({ title, to }: Props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (to) {
      // If a specific route is provided, navigate to it
      navigate(to);
    } else {
      // Otherwise, go back one step in the navigation stack
      navigate(-1);
    }
  };

  return (      
  <BackContainer onClick={handleBack}>
    <ArrowButton>
      <img alt="backbutton" src={chevron} />
    </ArrowButton>
    <TypographyStyles.BodyLRegular color={colors.key}>{title}</TypographyStyles.BodyLRegular>
  </BackContainer>
)
}

export default BackButton;