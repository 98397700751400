import { v4 as uuidv4 } from 'uuid';
import { getJourneyDurationInMinutes } from "./durationUtils";

export type Leg = any;

type TransferStat = {
  averageTransferTime: number;
  percentageMissedConnections: number
}

export type Journey = {
  price: Price;
  reliability: {
    reliabilityScore: number;
  }
  legs: Leg[];
  transferStats: TransferStat[]
  journeyDelayStats: {
    averageDelayAtDest?: number | null;
    journeyCancellationRate?: number | null;
  }
}

type Tag = 'arrives-first' | 'cheapest' | 'fastest' | 'most-reliable' | 'direct' | 'least-transfers'

type Price = {
  amount: number;
  currency: string;
  hint?: any;
}

export type MappedJourney = Journey & {
  tagsData: DataForTags;
  tags?: string[];
  id: string;
}

type DataForTags = {
  price: number;
  arrivalTime: string;
  duration: number;
  reliabilityScore: number;
  direct: boolean;
  numberOfTransfers: number;
}

const generateTagsData = (journey: Journey): DataForTags => {
  const getArrivalTime = (journey: Journey) => {
    const lastLeg = journey.legs[journey.legs.length - 1];
    if (!!lastLeg.arrival) return lastLeg.arrival;
    return lastLeg.plannedArrival
  } 

  return {
    price: journey.price.amount,
    reliabilityScore: journey.reliability.reliabilityScore,
    arrivalTime: getArrivalTime(journey),
    duration: getJourneyDurationInMinutes(journey.legs),
    direct: journey.legs.length === 1,
    numberOfTransfers: journey.legs.length - 1
  }
}

const generateTags = (
  tagsData: DataForTags, 
  context: JourneyContext,
  journey: Journey
): Tag[] => {
  const journeyDepartureTime = getJourneyDepartureTime(journey);
  const journeyArrivalTime = getJourneyArrivalTime(journey);
  
  const tags = [
    tagsData.direct ? 'direct' : undefined,
    context.cheapest === tagsData.price ? 'cheapest' : undefined,
    context.mostReliable === tagsData.reliabilityScore ? 'most-reliable' : undefined,
    context.fastest === tagsData.duration ? 'fastest' : undefined,
    // Only show arrives-first if this journey isn't also the first to depart
    context.arrivesFirst === journeyArrivalTime && context.departsFirst !== journeyDepartureTime ? 'arrives-first' : undefined,
    context.showLeastTransfers && context.leastTransfers === tagsData.numberOfTransfers ? 'least-transfers' : undefined
  ].filter(el => el)

  return tags as Tag[];
}


const getJourneyDepartureTime = (journey: Journey): string => {
  const firstLeg = journey.legs[0];
  return firstLeg.departure || firstLeg.plannedDeparture;
};

const getJourneyArrivalTime = (journey: Journey): string => {
  const lastLeg = journey.legs[journey.legs.length - 1];
  return lastLeg.arrival || lastLeg.plannedArrival;
};

type JourneyContext = {
  cheapest: number;
  mostReliable: number;
  fastest: number;
  arrivesFirst: string;
  departsFirst: string;
  leastTransfers: number;
  showLeastTransfers: boolean;
}

const calculateJourneyContext = (journeys: Journey[]): JourneyContext => {
  let cheapest = Number.MAX_VALUE;
  let mostReliable = 0;
  let fastest = Number.MAX_VALUE;
  let arrivesFirst = getJourneyArrivalTime(journeys[0]);
  let departsFirst = getJourneyDepartureTime(journeys[0]);
  let leastTransfers = Number.MAX_VALUE;

  const hasDirect = journeys.some(journey => journey.legs.length === 1);

  for (let journey of journeys) {
    const tagsData = generateTagsData(journey);

    if (tagsData.price > 0 && tagsData.price < cheapest) {
      cheapest = tagsData.price;
    }
    if (tagsData.reliabilityScore > 0 && tagsData.reliabilityScore > mostReliable) {
      mostReliable = tagsData.reliabilityScore;
    }
    if (tagsData.duration < fastest) {
      fastest = tagsData.duration;
    }
    
    const journeyArrivalTime = getJourneyArrivalTime(journey);
    const journeyDepartureTime = getJourneyDepartureTime(journey);

    if (new Date(arrivesFirst) > new Date(journeyArrivalTime)) {
      arrivesFirst = journeyArrivalTime;
    }
    if (new Date(departsFirst) > new Date(journeyDepartureTime)) {
      departsFirst = journeyDepartureTime;
    }

    if (hasDirect === false && tagsData.numberOfTransfers < leastTransfers) {
      leastTransfers = tagsData.numberOfTransfers;
    }
  }

  const showLeastTransfers = journeys.filter(j => generateTagsData(j).numberOfTransfers === leastTransfers).length !== journeys.length;

  return {
    cheapest,
    mostReliable,
    fastest,
    arrivesFirst,
    departsFirst,
    leastTransfers,
    showLeastTransfers
  };
}

const mapLeg = (leg: Leg) => {
  return {
    ...leg,
    arrivalDelay: leg.arrivalDelay ? leg.arrivalDelay / 60 : leg.arrivalDelay,
    departureDelay: leg.departureDelay ? leg.departureDelay / 60 : leg.departureDelay
  }
}

const sortJourneysByDeparture = (journeys: Journey[]): Journey[] => {
  return [...journeys].sort((a, b) => {
    const timeA = new Date(getJourneyDepartureTime(a));
    const timeB = new Date(getJourneyDepartureTime(b));
    return timeA.getTime() - timeB.getTime();
  });
};

export const mapJourneysData = (journeys: Journey[], existingJourneys: Journey[] = []): MappedJourney[] => {
  // Combine existing and new journeys
  const allJourneys = sortJourneysByDeparture([...existingJourneys, ...journeys]);

  // Calculate context for all journeys
  const journeyContext = calculateJourneyContext(allJourneys);

  // Map and tag journeys
  const mappedJourneys = allJourneys.map(journey => ({
    ...journey,
    id: uuidv4(),
    tagsData: generateTagsData(journey),
    legs: journey.legs.map(mapLeg),
    tags: generateTags(generateTagsData(journey), journeyContext, journey)
  }));

  return mappedJourneys;
}
