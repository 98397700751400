import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { colors } from '../../utils/colors';
import PageHeader from '../PageHeader';
import { TransferHistory } from './Types/transferHistory';
import TransferHistoryCard from './TransferHistoryCard';
import TransferDistributionCard from './TransferDistributionCard';
import TransferTimeEvolutionCard from './TransferEvolutionCard';
import FeedbackCard from '../FeedbackCard';



const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  box-sizing: border-box;
  align-items: left;
  gap: 32px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 24px 16px; // Add 24px padding at the top and bottom
  background: ${colors.white};
  border-bottom: 2px solid ${colors.keyHover};
`;

// Define a type for delay history response if different from LineStatisticsResponse
type TransferHistoryResponse = TransferHistory[];

const TransferStatistics = () => {
    const location = useLocation();

    // Extract both legs from location.state
    const firstLeg = location.state?.firstLeg;
    const secondLeg = location.state?.secondLeg;

    const [transferHistoryData, setTransferHistoryData] = useState<TransferHistoryResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!firstLeg || !secondLeg) return;

        const fetchData = async () => {
          try {
            setLoading(true);
            setError(null);
    
            // Create the request body
            const body = JSON.stringify({
              firstLegLineName: firstLeg.line.name,
              firstLegDestinationId: firstLeg.destination.id,
              secondLegLineName: secondLeg.line.name,
              secondLegOriginId: secondLeg.origin.id,
            });

            // Fetch the data from the Netlify function
            const response = await fetch('/.netlify/functions/getTransferHistory', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body,
            });

            if (!response.ok) {
              throw new Error('No transfer statistics available for these trains');
            }

            const transferHistoryData = await response.json();

            setTransferHistoryData(transferHistoryData);
          } catch (err) {
            if (err instanceof Error) {
              setError(err.message);
            } else {
              setError('An unknown error occurred');
            }
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
    }, [firstLeg, secondLeg]);

    // Scroll to top on component mount
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

  return (
    <>
      <HeaderContainer>
        <PageHeader
          title={`Transfer statistics`}
          subtitle={`${firstLeg?.line?.name} ➜ ${secondLeg?.line?.name} at ${firstLeg.destination.name}`}
          backButtonTitle="Journey details"
        />
      </HeaderContainer>
      <Container>
          {loading && <div>Loading...</div>}
          {error && <div>Error: {error}</div>}
          {transferHistoryData && (
              <TransferDistributionCard 
                  transferHistoryData={transferHistoryData} 
              />
          )}
          {transferHistoryData && (
              <TransferTimeEvolutionCard 
                  transferHistoryData={transferHistoryData} 
              />
          )}
          {transferHistoryData && (
              <TransferHistoryCard 
                  transferHistoryData={transferHistoryData} 
              />
          )}
          <FeedbackCard source={`Transfer statistics from ${firstLeg?.line?.name} to ${secondLeg?.line?.name} at ${firstLeg.destination.name}`} />
        </Container>
    </>
  );
};

export default TransferStatistics;