import styled from "styled-components"
import { colors } from "../../utils/colors";

import Footer from "./Footer";
import Sitemap from "./Sitemap";
import FAQ from "./FAQs";

const Container = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
border-top: 1px solid ${colors.grey400};
`

const FooterSection = () => {
    return (
      <Container>
        <FAQ/>
        <Sitemap/>
        <Footer/>
      </Container>
    );
  };
  
  export default FooterSection;