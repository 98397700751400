import React from 'react';
import styled from 'styled-components';
import { colors } from "../../utils/colors";
import * as TypographyStyles from '../TypographyStyles';
import { TransferHistory } from './Types/transferHistory';

const ContainerCard = styled('div')`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border-bottom: 2px solid ${colors.grey400};
    background: ${colors.white};
`

const CardHeader = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`

const Chart = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
`;

const Cohort = styled.div`
display: flex;
align-items: center;
gap: 14px;
align-self: stretch;
`;

const HorizontalBar = styled.div`
display: flex;
height: 16px;
align-items: center;
flex: 1 0 0;
border-radius: 6px;
background: ${colors.grey300};
`;

interface BarFillProps {
    width: string;
    color: string;  // Add color prop
}

const BarFill = styled.div<BarFillProps>`
    height: 16px;
    border-radius: 6px;
    background-color: ${({ color }) => color};  // Use color prop for background color
    width: ${({ width }) => width};
`;

const CategoryText = styled(TypographyStyles.BodySRegular)`
color: ${colors.grey800};
width: 60px;
`;

const ValueText = styled(TypographyStyles.BodySRegular)`
color: ${colors.grey800};
width: 25px;
text-align: right;
`;



interface TransferDistributionCardProps {
    transferHistoryData: TransferHistory[];
}

// Function to map category names to colors
const getBarColor = (label: string) => {
    switch (label) {
        case 'Missed': return colors.red;
        case '0-4 min': return colors.red;
        case '5-9 min': return colors.orange;
        case '10-14 min': return colors.green;
        case '15-19 min': return colors.green;
        case '20+ min': return colors.green;
        default: return colors.grey300;  // Default in case of unexpected labels
    }
};

const TransferDistributionCard: React.FC<TransferDistributionCardProps> = ({ transferHistoryData }) => {

    // Convert delay from seconds to minutes and bucket the data
    const buckets = [0, 0, 0, 0, 0, 0];
    transferHistoryData.forEach(entry => {
        const transferTime = entry.actualTransferTimeMinutes;

        // Handle possible null or undefined delay values
        if (transferTime != null) {

            if (transferTime <= 0) buckets[0] += 1;
            else if (transferTime <= 4) buckets[1] += 1;
            else if (transferTime <= 9) buckets[2] += 1;
            else if (transferTime <= 14) buckets[3] += 1;
            else if (transferTime <= 19) buckets[4] += 1;
            else buckets[5] += 1;
        }
    });

    const totalEntries = transferHistoryData.length;
    const percentages = buckets.map(count => (count / totalEntries) * 100);

    // Define new X-axis labels
    const xAxisLabels = [
        'Missed',       // Negative delay
        '0-4 min',     // 0-4 min
        '5-9 min',     // 5-9 min
        '10-14 min',   // 10-14 min
        '15-19 min',   // 15-29 min (combined)
        '20+ min',     // 30+ min
    ];

    return (
        <ContainerCard>
            <CardHeader>
                <TypographyStyles.BodyLSemibold color={colors.black}>
                    Transfer time distribution
                </TypographyStyles.BodyLSemibold>
                <TypographyStyles.BodyLRegular color={colors.grey600}>
                    (last 30 days)
                </TypographyStyles.BodyLRegular>
            </CardHeader>
            <Chart>
                {percentages.map((percentage, index) => (
                    <Cohort key={index}>
                        <CategoryText>{xAxisLabels[index]}</CategoryText>
                        <HorizontalBar>
                            <BarFill 
                                width={`${percentage}%`} 
                                color={getBarColor(xAxisLabels[index])}  // Pass the label to determine the color
                            />
                        </HorizontalBar>
                        <ValueText>{percentage.toFixed(0)}%</ValueText>
                    </Cohort>
                ))}
            </Chart>
        </ContainerCard>
    );
};

export default TransferDistributionCard